*{
  margin: 0;
  padding: 0;    
  text-decoration: none;
  box-sizing: border-box;
}


@font-face {
  font-family:'Satoshi-Bold';
  src: url(./Fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Bold.otf);
}

@font-face {
  font-family:'Satoshi-Medium';
  src: url(./Fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Medium.otf);
}


@font-face {
  font-family:'Satoshi-Regular';
  src: url(./Fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Regular.otf);
}

@font-face {
  font-family:'Satoshi-Light';
  src: url(./Fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Light.otf);
}

@font-face {
  font-family:'Satoshi-Black';
  src: url(./Fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Black.otf);
}



@font-face {
  font-family:'Sans-Bold';
  src: url(./Fonts/GeneralSans_Complete/GeneralSans_Complete/Fonts/OTF/GeneralSans-Bold.otf);
}

@font-face {
  font-family:'Sans-Medium';
  src: url(./Fonts/GeneralSans_Complete/GeneralSans_Complete/Fonts/OTF/GeneralSans-Medium.otf);
}

@font-face {
  font-family:'Sans-Regular';
  src: url(./Fonts/GeneralSans_Complete/GeneralSans_Complete/Fonts/OTF/GeneralSans-Regular.otf);
}























































































































































































































































































































































































































































































































