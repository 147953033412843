.footer{
    background: #650088;
    color: #fff;
    margin-top: 100px;
    margin-bottom: 0;
    padding: 2% 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer p{
    font-family:'Sans-Medium'; 
}

.footer span{
    font-family:'Sans-Medium';
}


@media screen and (max-width:900px){
    .footer{
        margin-top: 100px;
        padding: 4% 5%;
        display: flex;
        gap: 40px;
       flex-direction: column;
       text-align: center;
    }
}