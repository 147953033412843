.contact{
  width: 100%;
  height: 40vh;
  position: relative;
  overflow: hidden;
  background: url(https://res.cloudinary.com/dxnukbo0u/image/upload/v1710808584/9107_niq2oi.jpg) no-repeat center;
  background-size: cover;
}

.contact-overlay{
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #FFF;
  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75));
}

.contact-overlay h2{
  font-size: 36px;
  font-family:'Sans-Bold';
}

.contact-con{
    width: 90%;
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
  
  
  .contact-form{
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  form{
      width: 100%;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      gap: 10px;
  }
  
    form label{
      font-family:'Satoshi-Medium';
    }
  
    .pad{
      margin-top: 20px;
    }
  
    form input{
       background: #f2f2f2;
       font-family:'Satoshi-Medium';
       color: #5F5D5D;
       outline: none;
       border: none;
       padding: 20px;
    }
  
    textarea{
      background: #f2f2f2;
      font-family:'Satoshi-Medium';
      color: #5F5D5D;
      outline: none;
      border: none;
      padding: 10px 20px;
      height: 100px;
    }
  
    .submit-btn{
      background: #650088;
      color: #fff;
      margin-top: 30px;
      transition: all ease-in 0.3s;
      font-family:'Satoshi-Medium';
      cursor: pointer;
    }
  
    .submit-btn:hover{
      background: #8F4DFC;
    }
  
  
    .toast-message {
      background: darkblue;
      color: #fff;
      font-size: 18px;
      width: 34vw;
      padding: 30px 20px;
    }
  

    @media screen and (max-width:900px){
      .toast-message {
        background: darkblue;
        color: #fff;
        font-size: 18px;
        width: 80vw;
        padding: 30px 20px;
      }
    
      .contact-con{
        width: 100%;
        margin: 0 auto;
        margin-top: 80px;
        margin-bottom: 0;
        padding-bottom: 0;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 50px;
      }
   
      .contact-form{
        width: 80%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      form{
        width: 100%;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    
     
      .pad{
        margin-top: 15px;
      }
  
      form input{
        background: #f2f2f2;
        color: #5F5D5D;
        outline: none;
        border: none;
        padding: 15px;
     }

    }