.hero{
  position: relative;  
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5% 5%;
  padding-top: 60px;
}

.hero-overlay{
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-basis: 55%;
  gap: 20px;
}

.hero-overlay h2{
  color:  #000;
  font-size: 32px;
  font-family:'Sans-Bold';
}

.hero-overlay h2 strong{
  color: #E5761A;
}

.hero-overlay p{
  margin-top: 0;
  width: 88%;
  color:  #000;
  font-size: 18px;
  line-height: 28px;
  font-family:'Sans-Medium';
}

.about-btn{
  width: 180px;
  margin-top: 30px;
}

.hero-overlay button{
  gap: 30px;
  background: #E5761A;
  color: #fff;
  font-family:'Sans-Medium';
  padding: 15px;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  transition: all ease-in 0.3s;
}

.hero-overlay button:hover{
  background: #c7600c;
}

.hero-img{
  flex-basis: 45%;
}

.hero-img img{
 width: 100%;
 height: 100%;;
 border-radius: 10px;
 object-fit: cover;

}

 
@media screen and (max-width:900px){
  .hero{
    position: relative;  
    width: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    padding-top: 80px;
  }

  .hero-overlay{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      padding: 2% 5%;
      padding-bottom: 50px;
      flex-basis: 100%;
    }
  
    .hero-overlay h2{
      width: 100%;
      font-size: 24px;
      text-align: center;
    }
  
    .hero-overlay p{
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }   
}
