.choose{
    width: 90%;
    margin: 0 auto;
    margin-top: 10px;
}

.choose-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.choose-header h2{
    font-family:'Sans-Bold';  
    font-size: 32px; 
}

.choose-header p{
    font-family:'Sans-Medium';
}

.choose-con{
    margin-top: 40px;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.choose-box{
    max-width: 250px;
    background: #9e48e941;
    padding: 30px 15px;
    border-radius: 10px;
}

.choose-con p{
    font-family:'Sans-Bold';    
    font-size: 18px;
    margin-bottom: 5px;
}

.choose-con span{
    font-family:'Sans-Medium';    
}

@media screen and (max-width:900px){
    .choose{
        width: 90%;
        margin: 0 auto;
        margin-top: 40px;
    }

    .choose-header h2{
        font-family:'Sans-Bold';  
        font-size: 24px; 
    }
    
    .choose-header p{
        font-family:'Sans-Medium';
    }

    .choose-con{
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: space-between;
        align-items: center;
        text-align: center;
    }  
}