.work{
    width: 90%;
    margin: 0 auto;
    margin-top: 80px;
    padding:  5% 0;
    background: #650088;
    color: #fff;
    border-radius: 40px;
}

.work-con{
    padding: 0 5%;
    margin-top: 40px;
    display: flex;
    gap: 40px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.work-con p{
    font-family:'Sans-Bold';    
}

.work-con span{
    font-family:'Sans-Medium';    
}

.work-box{
    max-width: 250px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    color: #3f3f3f;
    padding: 20px 10px;
    border-radius: 20px;
}

.work-box div{
    border: 2px solid #E5761A;;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.work-box div h2{
    color: #E5761A;;
     font-family:'Sans-Bold'; 
}

.work-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
}

.work-btn button{
    background: #E5761A;
    color: #fff;
    font-family:'Satoshi-Medium';  
    border: none;
    border-radius: 30px;
    outline: none;
    padding: 10px 15px;
    cursor: pointer;
    transition: all ease-in 0.3s;
}

.work-btn button:hover{
    background: #590212;  
}

@media screen and (max-width:900px){
    .work{
        width: 100%;
        margin: 0 auto;
        margin-top: 80px;
        padding:  10% 0;
        background: #650088;
        color: #fff;
        border-radius: 0;
    }
    
    .work-con{
        padding: 0 10%;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: space-between;
        align-items: center;
        text-align: center;
    }  
}