.fast{
    width: 90%;
    margin: 0 auto;
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

.fast-left{
    flex-basis: 45%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.fast-left h2{
    font-family:'Sans-Bold';
    font-size: 32px;
}

.fast-left h2 strong{
    color: #F29F05;
}


.value {
    flex-basis: 45%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.misval-text{
    display: flex;
    gap: 15px;
}

.misval-text p{
    font-family:'Sans-Medium';
    font-size: 18px;
    line-height: 28px;
    margin-top: -5px;
    color: #1f1f1f;
}

.misval-text img{
    width: 20px;
    height: 20px;
    object-fit: cover;
}

.fast-right{
    flex-basis: 45%;
}

.fast-right img{
    width: 100%;
}

@media screen and (max-width:900px){
    .fast{
        width: 90%;
        margin: 0 auto;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .fast-left{
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        
    }

    
    .fast-left h2{
        font-family:'Sans-Bold';
        font-size: 24px;
        text-align: center;
    }

    .fast-right{
        flex-basis: 100%;
    }

      
}