.brief{
    width: 90%;
    margin: 0 auto;
    margin-top: 80px;
    background: #E5761A;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2% 0;
    border-radius: 40px;
}

.brief h2{
    font-family:'Sans-Bold';
    font-size: 32px;
    width: 55%;
    text-align: center;
}

.brief-btn button{
    background: #650088;
    color: #fff;
    font-family:'Sans-Medium'; 
    border: none;
    border-radius: 30px;
    outline: none;
    padding: 10px 15px;
    margin-top: 30px;
    cursor: pointer;
    transition: all ease-in 0.3s;
}

.brief-btn button:hover{
    background: #8c0bb8; 
}

@media screen and (max-width:900px){
    .brief{
        width: 90%;
        margin: 0 auto;
        margin-top: 60px;
        background: #F29F05;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 7% 0;
        border-radius: 20px;
    }

    
    .brief h2{
        font-family:'Sans-Bold';
        font-size: 24px;
        width: 85%;
        text-align: center;
    }
}